<template>
  <div>
    <patientSelect @onConfirm="patientConfirm" />
    <load-more :data-source="dataList" :total="total" :loading="loading" @load-more="onLoad">
      <CheckupItem :data="dataList" />
    </load-more>
  </div>
</template>

<script>
//  import { userModel } from '@/api/user'
import loadMore from '@/assets/js/mixin/load-more'
import CheckupItem from '@/components/checkupItem/checkupItem.vue'
import {patientModel} from "../../../api/patient"

export default {
  name:'List',
  components:{
    CheckupItem
  },
  mixins: [loadMore],
  data(){
    return{
      dataList:[],
      page:1,
      pageSize:10,
      patxm:'',
      zjh:'',
      total:0,
      loading: false
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      let params={
        isPatAll: this.zjh =='' ? '1': '0',
        page: this.page,
        pageSize: this.pageSize,
        patxm: this.patxm,
        zjh: this.zjh
      }
      patientModel.jcReportList(params).then(res=>{
        this.dataList.push(...(res.data || []))
        this.total = res.count
      }).finally(() => {
        this.loading = false
      })
    },
    //选择就诊人
    patientConfirm(value){
      this.patxm=value.text
      this.zjh=value.zjh
      this.page=1
      this.dataList=[]
      this.loading = true
      this.getList()
    },
    //分页加载更多
    onLoad(){
      this.loading=true
      this.page+=1
      this.getList()
    }
  }
}
</script>
<style scoped>

</style>
